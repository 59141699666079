@tailwind base;
html{
  @apply scroll-smooth;
}
@tailwind components;
@tailwind utilities;

@layer components {
  .bg-primary {
    @apply bg-white
  }
  .bg-secondary {
    @apply bg-gray-100
  }
  .bg-blue-secondary {
    @apply bg-slate-50
  }
  .text-primary {
    @apply text-base text-gray-500
  }
  .text-title {
    @apply text-base text-gray-900 font-bold 
  }
  .text-secondary {
    @apply text-base font-bold text-red-900
  }
  .text-secondary-black {
    @apply text-base font-bold text-black
  }
  .text-title-primary {
    @apply text-3xl text-secondary 
  }
  .text-title-primary-black {
    @apply text-3xl text-secondary-black 
  }
  .text-title-secondary {
    @apply text-3xl text-title
  }
  .text-title-subtitle {
    @apply text-lg text-title
  }
  .btn-pink {
    @apply w-52 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500
  }
  .btn-green {
    @apply w-52 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400
  }
  .btn-ecyd-base {
    @apply w-52 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2
  }
}

html, body {
  color: black;
}

.disable-link {
  pointer-events: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.loader {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  margin-left: 6px;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}

.loader-ecyd {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader-ecyd::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 4px solid #db1d76;
  animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

.swal2-loader {
  border-color: #db1d76 transparent #db1d76 transparent !important;
}

.loading-dots:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}

.swal2-html-container > small {
  color: rgb(48, 133, 214);
  margin-top: 20px;
}

.swal2-html-container > p {
  margin-bottom: 10px;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: black;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;
    }
}